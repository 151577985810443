<script setup>

import { onMounted, ref } from "vue"
import { useFbsStore } from "@/store/fbs"
import { useRoute } from 'vue-router'

import FormFbs from "@/components-uhas/forms/FormFbs.vue"

const fbsStore = useFbsStore()
const route = useRoute()

const fbs = ref({ _: false })
const loading = ref(false)

onMounted(() => {
  loading.value = true
  fbsStore.show(route.params.id).then(({ data }) => {
    if (data.error) {
      return
    }

    fbs.value = { ...data.fbs }

    loading.value = false
  })
})

</script>

<template>
  <div class="block-section">
    <div class="block-header">
        <span class="block-title">
          <div>Show #{{ fbs.id }}</div>
        </span>

        <div class="flex align-items-center justify-content-center">
          <Button label="Back" class="p-button-text p-button-plain" @click="$router.go(-1)" />
        </div>
    </div>
    <div class="block-content">
        <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
          <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
            <div>
              
              <FormFbs
                v-if="fbs._ !== false"
                v-model="fbs"
              />

            </div>
          </div>
        </div>
    </div>
  </div>
</template>
