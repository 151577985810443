<script setup>
  import { computed, defineEmits, defineProps, ref, watch } from 'vue'
  import { useVuelidate } from "@vuelidate/core"

  import form from '@/scripts/form'

  const changed = ref(false)
  const loading = ref(false)

  const emit = defineEmits(['update:modelValue'])
  const props = defineProps(['id', 'modelValue'])

  const modelValue = computed(() => props.modelValue)

  const rules = {
  }

  const model = form.initial(rules, modelValue.value)

  const v$ = useVuelidate(rules, model)

  watch(model, (newValue) => {
    changed.value = true

    emit('update:modelValue', newValue)
  })

  loading.value = false
</script>

<template>
  <div>{{ v$ }}</div>
</template>
