import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useFbsStore = defineStore('fbs', {
  actions: {
		index: ({ filter, page = 1 }) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/fbss?page=${page}${ filter ? '&filter=' + filter : '' }`),
		show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/fbss/${id}`),
    store: (data) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/fbss`, data),
    destroy: (id) => axios.delete(`${VUE_APP_ENDPOINT_URL}/api/admin/fbss/${id}`),
  }
})
